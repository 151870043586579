const modalWindow = {
  show: () => {

    const modalElem = document.getElementById('categoryModal');
    const innerBox = modalElem.querySelector('.category-modal-box');

    modalElem.style.display = 'block';

    setTimeout(()=>{
      modalElem.classList.add('show');
    },100)
    

    let sp;

    modalElem.addEventListener('click', function showModal(e) {

      e.currentTarget.classList.remove('show');
      e.currentTarget.removeEventListener('click', showModal);
      innerBox.removeEventListener('click', sp);

    });

    innerBox.addEventListener('click', sp = (e)=> {
      e.stopPropagation();
    });

  }
}

const Category = ()=>{

  const btns = document.querySelectorAll('#category a.box');

  for(let i=0;i<btns.length;i++){
    btns[i].addEventListener('click',(e)=>{
      e.preventDefault();
      modalWindow.show();
    });
  }

}

export default Category;