import axios from "axios";
import { ApiRoutes } from "src/api/routes";

export default class Cart {
  createCart(params) {
    return axios.post(ApiRoutes.cartPath(), params).then(response => {
      return response.data;
    });
  }

  fetchCart(spreeOrderToken, params, source = axios.CancelToken.source()) {
    return axios
      .get(ApiRoutes.cartPath(), {
        cancelToken: source.token,
        // カート画面で同一エンドポイントに複数回リクエストを投げると、ブラウザバック時にキャッシュされるためダミーparamsを追加して回避
        params: { ...params, ...{ _token: spreeOrderToken } },
        headers: { "X-Spree-Order-Token": spreeOrderToken }
      })
      .then(response => {
        return response.data;
      });
  }

  addItem(spreeOrderToken, params) {
    return axios
      .post(ApiRoutes.cartAddItemPath(), params, {
        headers: { "X-Spree-Order-Token": spreeOrderToken }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  setQuantity(spreeOrderToken, params, source = axios.CancelToken.source()) {
    return axios
      .patch(ApiRoutes.cartSetQuantityPath(), params, {
        cancelToken: source.token,
        headers: { "X-Spree-Order-Token": spreeOrderToken }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  removeItem(spreeOrderToken, lineItemId) {
    return axios
      .delete(ApiRoutes.cartRemoveItemPath(lineItemId), {
        headers: { "X-Spree-Order-Token": spreeOrderToken }
      })
      .then(response => {
        return response.data;
      });
  }

  keepProduct(spreeOrderToken, lineItemId) {
    return axios
      .post(
        ApiRoutes.cartKeepLinePath(lineItemId),
        {},
        {
          headers: { "X-Spree-Order-Token": spreeOrderToken }
        }
      )
      .then(response => {
        return response.data;
      });
  }

  removeKeepOrder(token, variantId) {
    return axios
      .post(
        ApiRoutes.removeKeepOrderPath(variantId),
        {},
        {
          headers: { "X-Spree-Order-Token": token }
        }
      )
      .then(response => {
        return response.data;
      });
  }

  applyCouponCode(spreeOrderToken, params) {
    return axios
      .patch(ApiRoutes.cartApplyCouponCodePath(), params, {
        headers: { "X-Spree-Order-Token": spreeOrderToken }
      })
      .catch(error => {
        return error.response;
      });
  }
}
