import { ApiRoutes } from "src/api/routes";
import axios from "axios";

export default class Address {
  createAddress(spreeOauthToken, params) {
    return axios
      .post(`${ApiRoutes.createAddressPath()}`, params, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  }

  show(spreeOauthToken, id) {
    return axios
      .get(ApiRoutes.addressPath(id), {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response.data;
      });
  }

  updateAddress(spreeOauthToken, id, params) {
    return axios
      .patch(`${ApiRoutes.addressPath(id)}`, params, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  }

  destroyAddress(spreeOauthToken, id) {
    return axios
      .delete(ApiRoutes.addressPath(id), {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response.data;
      });
  }
}
