import Swiper from 'swiper';

const Ranking = ()=>{

  /*
  カテゴリボタン
  */
  
  const ctgBtnsSetOn = (num)=>{
    //on外す
    const ctgBtns = document.querySelectorAll('#rankingCtgSwiper li');
    for (let i = 0; i < ctgBtns.length; i++) {
      ctgBtns[i].classList.remove('on');
    }
    //onつける
    let className = '.ctg' + num;
    const btns = document.querySelectorAll(className);
    for (let i = 0; i < btns.length; i++) {
      btns[i].classList.add('on');
    }
  }
  /*
  const ctgBtns = document.querySelectorAll('#rankingCtgSwiper a');

  for(let i=0;i<ctgBtns.length;i++){
    ctgBtns[i].addEventListener('click',(e)=>{
      e.preventDefault();
      ctgBtnsReset();
      let href = e.currentTarget.getAttribute('href');
      const parent = e.currentTarget.parentNode;
      parent.classList.add('on');
    });
  }
  */
  /*
  Ctg Swiper
  */
  let ctgSwiper = new Swiper('#rankingCtgSwiper',{
    loop:true,
    speed:300,
    spaceBetween: 10,
    slidesPerView: 3,
    navigation: {
      nextEl: '#ranking .ctg .next',
      prevEl: '#ranking .ctg .prev',
    },
    breakpoints: {
      769: {
        loop:false,
        allowTouchMove: false,
        slidesPerView: 6,
        spaceBetween: 12
      }
    }
  });

  ctgSwiper.on('click',(e)=>{
    //on外す
    /*
    const ctgBtns = document.querySelectorAll('#rankingCtgSwiper li');
    for (let i = 0; i < ctgBtns.length; i++) {
      ctgBtns[i].classList.remove('on');
    }
    */
    
    //スライド
    const num = e.target.getAttribute('data-num');
    boxSwiper.slideToLoop(parseInt(num)-1);
    e.preventDefault();

    //onつける
    /*
    let className = '.ctg'+num;
    const btns = document.querySelectorAll(className);
    for (let i = 0; i < btns.length; i++) {
      btns[i].classList.add('on');
    }*/

    ctgBtnsSetOn(num);
  });

  /*
  box Swiper
  */
  let boxSwiper = new Swiper('#rankingBoxSwiper', {
    loop: true,
    speed: 400,
    navigation: {
      nextEl: '#ranking .boxes-container .next',
      prevEl: '#ranking .boxes-container .prev',
    },
    breakpoints: {
      769: {
        speed: 0
      }
    }
  });

  boxSwiper.on('slideChange', () => {
    let boxIndex = boxSwiper.realIndex;
    ctgSwiper.slideToLoop(boxIndex);

    ctgBtnsSetOn(boxIndex + 1);
  });
}

export default Ranking;