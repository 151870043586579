import Swiper from 'swiper';
import Seekbar from "./_seekbar";

const Mylist = ()=>{

  /*
  Swiper
  */

  if (document.getElementById('favSwiper')){
    //お気に入りの総数
    let favSwiperWrapper = document.querySelector('#favSwiper .swiper-wrapper');
    let numFavSlides = favSwiperWrapper.childElementCount;
    const favSeekbar = new Seekbar('#favSeekbar');

    let favSwiper = new Swiper('#favSwiper',{
      speed:400,
      slidesPerView: 'auto',
      slidesPerGroup:1,
      breakpoints: {
        769: {
          allowTouchMove: false,
          slidesPerView: 'auto',
          spaceBetween: 24
        }
      }
    });
    favSwiper.on('slideChange', () => {
      favSeekbar.setProgress(favSwiper.realIndex, numFavSlides);
    });
  }

  if (document.getElementById('historySwiper')) {
    //履歴の総数
    let historySwiperWrapper = document.querySelector('#historySwiper .swiper-wrapper');
    let numHistorySlides = historySwiperWrapper.childElementCount;
    const historySeekbar = new Seekbar('#historySeekbar');

    let historySwiper = new Swiper('#historySwiper', {
      speed: 400,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      breakpoints: {
        769: {
          allowTouchMove: false,
          slidesPerView: 'auto',
          spaceBetween: 24
        }
      }
    });
    historySwiper.on('slideChange', () => {
      historySeekbar.setProgress(historySwiper.realIndex, numHistorySlides);
    });
  }
}

export default Mylist;