<template>
  <!--ランキング-->
  <section id="ranking" class="ranking">
    <h2><span class="en font-lato">RANKING / ランキング</span></h2>
    <div class="ctg">
      <div id="rankingCtgSwiper" class="swiper-container">
        <ul class="swiper-wrapper">
          <li class="on swiper-slide ctg1" data-num="1">電動<br class="sp" />アシスト</li>
          <li class="swiper-slide ctg2" data-num="2">電動子乗せ</li>
          <li class="swiper-slide ctg3" data-num="3">シティ</li>
          <li class="swiper-slide ctg4" data-num="4">キッズ</li>
          <li class="swiper-slide ctg5" data-num="5">折りたたみ</li>
          <li class="swiper-slide ctg6" data-num="6">スポーツ</li>
        </ul>
      </div>
      <div class="prev"><span class="icon-arrow-right"></span></div>
      <div class="next"><span class="icon-arrow-right"></span></div>
    </div>
    <div id="rankingBoxSwiper" class="boxes-container swiper-container">
      <div class="boxes swiper-wrapper">
        <div class="swiper-slide">
          <product-card-top-page-new-arrival v-for="p in electricAssistCycle" :key="`ranking1${p.id}`" :product="p" :enable-star="true" :isThreeProducts="true" />
        </div>
        <div class="swiper-slide">
          <product-card-top-page-new-arrival v-for="p in electricAassistChildOnCycle" :key="`ranking2${p.id}`" :product="p" :enable-star="true" :isThreeProducts="true" />
        </div>

        <div class="swiper-slide">
          <product-card-top-page-new-arrival v-for="p in cityCycle" :key="`ranking3${p.id}`" :product="p" :enable-star="true" :isThreeProducts="true" />
        </div>

        <div class="swiper-slide">
          <product-card-top-page-new-arrival v-for="p in kidsCycleVehicle" :key="`ranking4${p.id}`" :product="p" :enable-star="true" :isThreeProducts="true" />
        </div>

        <div class="swiper-slide">
          <product-card-top-page-new-arrival v-for="p in foldingCycleSmallCycle" :key="`ranking5${p.id}`" :product="p" :enable-star="true" :isThreeProducts="true" />
        </div>

        <div class="swiper-slide">
          <product-card-top-page-new-arrival v-for="p in sportsCycle" :key="`ranking6${p.id}`" :product="p" :enable-star="true" :isThreeProducts="true" />
        </div>
      </div>
      <div class="prev"><span class="icon-arrow-right"></span></div>
      <div class="next"><span class="icon-arrow-right"></span></div>
    </div>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";
import Ranking from "stylesheets/js/vendors/_ranking";
import ProductCardTopPageNewArrival from "src/components/ProductCardTopPageNewArrival";

export default {
  components: {
    ProductCardTopPageNewArrival
  },
  data: function() {
    return {
      electricAssistCycle: [],
      electricAassistChildOnCycle: [],
      cityCycle: [],
      kidsCycleVehicle: [],
      foldingCycleSmallCycle: [],
      sportsCycle: []
    };
  },
  beforeMount() {
    this.fetchProduct()
      .then(this.$nextTick)
      .then(() => {
        Ranking();
      });
  },
  methods: {
    async fetchProduct() {
      Promise.all([
        this.fetchElectricAssistCycle(),
        this.fetchElectricAassistChildOnCycle(),
        this.fetchCityCycle(),
        this.fetchKidsCycleVehicle(),
        this.fetchFoldingCycleSmallCycle(),
        this.fetchSportsCycle()
      ]);
    },
    async fetchElectricAssistCycle() {
      const res = await Client.product.fetchProduct({
        filter: { ranking_taxon_permalink: "electric-assist-cycle" },
        sort_by: "popular",
        include: "maker_brand,images",
        page: 1,
        per_page: 6
      });
      this.electricAssistCycle = await new Deserializer(res);
    },
    async fetchElectricAassistChildOnCycle() {
      const res = await Client.product.fetchProduct({
        filter: { ranking_taxon_permalink: "electric-assist-child-on-cycle" },
        sort_by: "popular",
        include: "maker_brand,images",
        page: 1,
        per_page: 6
      });
      this.electricAassistChildOnCycle = await new Deserializer(res);
    },
    async fetchCityCycle() {
      const res = await Client.product.fetchProduct({ filter: { ranking_taxon_permalink: "city-cycle" }, sort_by: "popular", include: "maker_brand,images", page: 1, per_page: 6 });
      this.cityCycle = await new Deserializer(res);
    },
    async fetchKidsCycleVehicle() {
      const res = await Client.product.fetchProduct({
        filter: { ranking_taxon_permalink: "kids-cycle-vehicle" },
        sort_by: "popular",
        include: "maker_brand,images",
        page: 1,
        per_page: 6
      });
      this.kidsCycleVehicle = await new Deserializer(res);
    },
    async fetchFoldingCycleSmallCycle() {
      const res = await Client.product.fetchProduct({
        filter: { ranking_taxon_permalink: "folding-cycle-small-cycle" },
        sort_by: "popular",
        include: "maker_brand,images",
        page: 1,
        per_page: 6
      });
      this.foldingCycleSmallCycle = await new Deserializer(res);
    },
    async fetchSportsCycle() {
      const res = await Client.product.fetchProduct({
        filter: { ranking_taxon_permalink: "sports-cycle" },
        sort_by: "popular",
        include: "maker_brand,images",
        page: 1,
        per_page: 6
      });
      this.sportsCycle = await new Deserializer(res);
    }
  }
};
</script>
