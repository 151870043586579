const storefrontPath = "/api/v2/storefront";

export const ApiRoutes = {
  authAccessTokenPath: () => "/auth_access_token",
  setAuthAccessTokenPath: () => "/set_auth_access_token",
  productHistoryPath: () => "/product_histories",
  apiTokenPath: () => "/api_tokens",
  setTokensPath: () => "/set_tokens",
  setTokenPath: () => "/set_token",
  productsPath: () => `${storefrontPath}/products`,
  productPath: id => `${storefrontPath}/products/${id}`,
  vendorsPath: () => `${storefrontPath}/vendors`,
  vendorPath: id => `${storefrontPath}/vendors/${id}`,
  newsListPath: () => `${storefrontPath}/news`,
  newsPath: id => `${storefrontPath}/news/${id}`,
  photoCarouselsPath: () => `${storefrontPath}/photo_carousels`,
  photoCarouselPath: id => `${storefrontPath}/photo_carousels/${id}`,
  productCarouselsPath: () => `${storefrontPath}/product_carousels`,
  promoBannersPath: () => `${storefrontPath}/promo_banners`,
  promoBannerPath: id => `${storefrontPath}/promo_banners/${id}`,
  featurePagesPath: () => `${storefrontPath}/feature_pages`,
  featurePagePath: id => `${storefrontPath}/feature_pages/${id}`,
  realStoresPath: () => `${storefrontPath}/real_stores`,
  realStorePath: id => `${storefrontPath}/real_stores/${id}`,
  realStoreNewsListPath: () => `${storefrontPath}/real_store_news`,
  realStoreInfosPath: () => `${storefrontPath}/real_store_infos`,
  realStoreNewsPath: id => `${storefrontPath}/real_store_news/${id}`,
  cartPath: () => `${storefrontPath}/cart`,
  cartAddItemPath: () => `${storefrontPath}/cart/add_item`,
  cartSetQuantityPath: () => `${storefrontPath}/cart/set_quantity`,
  cartRemoveItemPath: id => `${storefrontPath}/cart/remove_line_item/${id}`,
  cartApplyCouponCodePath: () => `${storefrontPath}/cart/apply_coupon_code`,
  statesPath: () => `${storefrontPath}/states`,
  paymentMethodsPath: () => `${storefrontPath}/checkout/payment_methods`,
  cartKeepLinePath: id => `${storefrontPath}/cart/add_keep_order/${id}`,
  removeKeepOrderPath: variantId => `${storefrontPath}/cart/remove_keep_order/${variantId}`,
  taxonsPath: () => `${storefrontPath}/taxons`,
  taxonPath: id => `${storefrontPath}/taxons/${id}`,
  serviceListPath: () => `${storefrontPath}/service_lists`,
  servicePath: id => `${storefrontPath}/service_lists/${id}`,
  notificationsPath: () => `${storefrontPath}/notifications`,
  notificationPath: id => `${storefrontPath}/notifications/${id}`,
  favoriteListPath: () => `${storefrontPath}/account/add_favorite_lists`,
  favoriteListAllPath: () => `${storefrontPath}/account/favorite_lists`,
  removeFavoriteListPath: variantId => `${storefrontPath}/account/remove_favorite_lists/${variantId}`,
  optionValuesPath: () => `${storefrontPath}/option_values`,
  rankingPath: () => `${storefrontPath}/rankings`,
  makerBrandListPath: () => `${storefrontPath}/maker_brands`,
  makerBrandPath: id => `${storefrontPath}/maker_brands/${id}`,
  accountOrdersPath: () => `${storefrontPath}/account/orders`,
  accountOrderPath: id => `${storefrontPath}/account/orders/${id}`,
  accountPath: () => `${storefrontPath}/account`,
  createAddressPath: () => `${storefrontPath}/account/addresses`,
  addressPath: id => `${storefrontPath}/account/addresses/${id}`,
  createReviewPath: () => `${storefrontPath}/account/reviews`,
  reviewPath: id => `${storefrontPath}/account/reviews/${id}`,
  createInquiryPath: () => `${storefrontPath}/inquiries`,
  realStoreTagsPath: () => `${storefrontPath}/real_store_tags`,
  regionPath: () => `${storefrontPath}/regions`,
  passwordPath: () => `${storefrontPath}/account/passwords`,
  telPath: () => `${storefrontPath}/account/tels`,
  brandSiteContentsPath: () => `${storefrontPath}/brand_site_contents`,
  brandSiteNewsListPath: () => `${storefrontPath}/brand_site_news`,
  brandSiteNewsPath: id => `${storefrontPath}/brand_site_news/${id}`
};
