import axios from "axios";
import { ApiRoutes } from "src/api/routes";

export default class States {
  fetchStates() {
    return axios.get(ApiRoutes.statesPath()).then(response => {
      return response.data;
    });
  }
}
