import axios from "axios";
import { ApiRoutes } from "src/api/routes";

export default class Checkout {
  paymentMethods(spreeOrderToken) {
    return axios
      .get(ApiRoutes.paymentMethodsPath(), {
        headers: { "X-Spree-Order-Token": spreeOrderToken }
      })
      .then(response => {
        return response.data;
      });
  }
}
