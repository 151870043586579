<template>
  <!--ショップ一覧-->
  <section class="shop-list">
    <div class="shop-list-inner">
      <h2><span class="en font-lato">SHOP</span><span class="ja">ショップ一覧</span></h2>
      <div class="boxes">
        <div v-for="vendor in vendors" :key="vendor.id" class="box">
          <a :href="Routes.vendorPath(vendor.permalink)">
            <div class="logo"><img :src="vendor.logo.style_large.url" /></div>
            <p class="name">{{ vendor.name }}</p>
            <p class="ctg">
              {{ displayTaxons(vendor.published_taxons) }}
            </p>
            <div class="maker">
              <div class="icon"><span class="icon-bicycle"></span></div>
              <ul>
                <li v-for="maker_brand in displayMakerBrands(vendor.top_display_maker_brands)" :key="maker_brand.id">
                  {{ maker_brand.name }}
                </li>
              </ul>
            </div>
          </a>
        </div>
      </div>
      <a class="more" href="/vendors/">
        もっと見る
        <div class="arrow"><span class="icon-arrow-right"></span></div>
      </a>
    </div>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";

// import { Routes } from "src/routes";

export default {
  data: function() {
    return {
      vendors: []
    };
  },
  beforeMount() {
    this.fetchVendor();
  },
  methods: {
    async fetchVendor() {
      const res = await Client.vendor.fetchVendor({
        filter: { published: true, pickuping: true },
        include: "logo,top_display_taxons,top_display_maker_brands",
        page: 1,
        per_page: 4
      });
      this.vendors = await Deserializer(res);
    },
    displayTaxons(taxons) {
      return taxons
        .slice(0, 2)
        .map(x => x.name)
        .join(" / ");
    },
    displayMakerBrands(maker_brands) {
      return maker_brands.slice(0, 2);
    }
  }
};
</script>
