import { ApiRoutes } from "src/api/routes";
import axios from "axios";

export default class Inquiry {
  createInquiry(params) {
    return axios
      .post(`${ApiRoutes.createInquiryPath()}`, params)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}
