import axios from "axios";
import qs from "qs";
import { ApiRoutes } from "src/api/routes";

export default class PhotoCarousel {
  fetchPhotoCarousel(params = {}) {
    return axios
      .get(ApiRoutes.photoCarouselsPath(), {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }

  show(id) {
    return axios.get(ApiRoutes.photoCarouselPath(id)).then(response => {
      return response.data;
    });
  }
}
