import authAccessToken from "src/api/endpoints/auth_access_token";
import ApiToken from "src/api/endpoints/api_token";
import Token from "src/api/endpoints/token";
import RealStore from "src/api/endpoints/real_store";
import RealStoreNews from "src/api/endpoints/real_store_news";
import RealStoreInfo from "src/api/endpoints/real_store_info";
import RealStoreTag from "src/api/endpoints/real_store_tag";
import Vendor from "src/api/endpoints/vendor";
import Product from "src/api/endpoints/product";
import Cart from "src/api/endpoints/cart";
import Checkout from "src/api/endpoints/checkout";
import Region from "src/api/endpoints/region";
import States from "src/api/endpoints/states";
import News from "src/api/endpoints/news";
import PhotoCarousel from "src/api/endpoints/photo_carousel";
import ProductCarousel from "src/api/endpoints/product_carousel";
import PromoBanner from "src/api/endpoints/promo_banner";
import FeaturePage from "src/api/endpoints/feature_page";
import Taxon from "src/api/endpoints/taxon";
import serviceList from "src/api/endpoints/service_list";
import Notification from "src/api/endpoints/notification";
import FavoriteList from "src/api/endpoints/favorite_list.js";
import ProductHistory from "src/api/endpoints/product_history";
import OptionValue from "src/api/endpoints/option_value";
import Ranking from "src/api/endpoints/ranking";
import makerBrand from "src/api/endpoints/maker_brand";
import Order from "src/api/endpoints/order";
import Account from "src/api/endpoints/account";
import Address from "src/api/endpoints/address";
import Review from "src/api/endpoints/review";
import Inquiry from "src/api/endpoints/inquiry";
import Tel from "src/api/endpoints/tel";
import BrandSiteContent from "src/api/endpoints/brand_site_content";
import BrandSiteNews from "src/api/endpoints/brand_site_news";

class Client {
  constructor() {
    this.authAccessToken = new authAccessToken();
    this.apiToken = new ApiToken();
    this.token = new Token();
    this.realStore = new RealStore();
    this.realStoreNews = new RealStoreNews();
    this.realStoreInfo = new RealStoreInfo();
    this.realStoreTag = new RealStoreTag();
    this.vendor = new Vendor();
    this.product = new Product();
    this.cart = new Cart();
    this.checkout = new Checkout();
    this.region = new Region();
    this.states = new States();
    this.news = new News();
    this.photoCarousel = new PhotoCarousel();
    this.productCarousel = new ProductCarousel();
    this.promoBanner = new PromoBanner();
    this.featurePage = new FeaturePage();
    this.taxon = new Taxon();
    this.serviceList = new serviceList();
    this.notification = new Notification();
    this.favoriteList = new FavoriteList();
    this.productHistory = new ProductHistory();
    this.optionValue = new OptionValue();
    this.ranking = new Ranking();
    this.makerBrand = new makerBrand();
    this.order = new Order();
    this.account = new Account();
    this.address = new Address();
    this.review = new Review();
    this.inquiry = new Inquiry();
    this.tel = new Tel();
    this.brandSiteContent = new BrandSiteContent();
    this.brandSiteNews = new BrandSiteNews();
  }
}
export default new Client();
