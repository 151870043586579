<template>
  <p class="price" style="white-space: normal;">
    <template>
      <span v-if="product.dual_pricing_is_display_cancel_price && product.on_sale_flag" class="price-3 size-3 line-through">本体価格&nbsp;{{ product.display_price }}</span>
      <br v-if="product.dual_pricing_is_display_cancel_price && product.on_sale_flag" />
      <span v-if="product.dual_pricing_is_display_brand_suggested_price" class="price-3 size-3 line-through">
        メーカー希望小売価格&nbsp;{{ product.brand_suggested_price | priceFormat }}円
      </span>
      <br v-if="product.dual_pricing_is_display_brand_suggested_price" />
      <span v-if="product.dual_pricing_is_display_discount_rate && product.on_sale_flag" class="price-3 size-3 bold red">
        {{ Math.floor(((1 * 10 - (product.sale_price / product.price) * 10) / 10) * 100) }}%OFF (税込)
      </span>
      <br v-if="product.dual_pricing_is_display_discount_rate && product.on_sale_flag" />
      <span v-if="product.dual_pricing_is_display_discount_rate && product.dual_pricing_header == 'discount_display'" class="price-3 size-3 bold red">
        {{ Math.floor(((1 * 10 - (product.price / product.brand_suggested_price) * 10) / 10) * 100) }}%OFF (税抜)
      </span>
      <br v-if="product.dual_pricing_is_display_discount_rate && product.dual_pricing_header == 'discount_display'" />
      <span class="price-2 bold bolder red size-2">本体価格</span>
      <span v-if="product.dual_pricing_display_timing === 'on_sale' && product.on_sale_flag" class="price-2 bold bolder red size-2">{{ product.sale_price | priceFormat }}円</span>
      <br v-if="product.dual_pricing_display_timing === 'on_sale' && product.on_sale_flag" />
      <span v-if="product.dual_pricing_display_timing === 'normal' || !product.on_sale_flag" class="price-2 bold bolder red size-2">
        {{ product.display_price }}
      </span>
      <br v-if="product.dual_pricing_display_timing === 'normal' || !product.on_sale_flag" />
      <span class="price-2 red">税込</span>&nbsp;<span class="price-taxin red price-2">{{ product.tax_included_price | priceFormat }}円</span>
      <br />
      <span v-if="product.dual_pricing_is_display_selling_period" class="size-3"> 販売期間 {{ product.start_at | slashDateFormat }} ~ {{ product.end_at | slashDateFormat }} </span>
      <span v-if="product.dual_pricing_is_display_sale_period && product.on_sale_flag" class="size-3">
        {{ product.sale_start_at | dateWeekTimeFormat }} ~ {{ product.sale_end_at | dateWeekTimeFormat }}までの期間限定価格
      </span>
    </template>
  </p>
</template>

<script>
import priceFormat from "src/filters/priceFormat";
import slashDateFormat from "src/filters/slashDateFormat";
import dateWeekTimeFormat from "src/filters/dateWeekTimeFormat";

export default {
  name: "ProductCardOrder",
  filters: {
    priceFormat,
    slashDateFormat,
    dateWeekTimeFormat
  },
  props: {
    product: { type: Object, required: true }
  }
};
</script>
