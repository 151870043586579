import { ApiRoutes } from "src/api/routes";
import axios from "axios";
import qs from "qs";

export default class Account {
  fetchAccount(spreeOauthToken, params = {}) {
    return axios
      .get(`${ApiRoutes.accountPath()}`, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` },
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }

  updateAccount(spreeOauthToken, params) {
    return axios
      .patch(`${ApiRoutes.accountPath()}`, params, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  }

  destroyAccount(spreeOauthToken, params) {
    return axios
      .delete(ApiRoutes.accountPath(), {
        headers: { Authorization: `Bearer ${spreeOauthToken}` },
        data: params
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}
