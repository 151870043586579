import dayjs from "dayjs";
import "dayjs/locale/ja";

export default function(date, format = "YYYY年M月D日(ddd) HH:mm") {
  if (!date) return "";
  date = date.toString();

  dayjs.locale("ja");
  return dayjs(date).format(format);
}
