export default class Seekbar {
  constructor(selector) {
    this.elem = document.querySelector(selector);
    this.bar = this.elem.querySelector('.bar');
    this.head = this.elem.querySelector('.head');
    this.barWidth = this.elem.clientWidth;
  }
  setProgress(index,numSlides){
    let per = index / (numSlides - 1);
    let width = per * this.barWidth;
    //this.bar.style.width = width + 'px';
    //this.head.style.left = width + 'px';
    this.bar.style.width = Math.floor(per * 100) + '%';
    this.head.style.left = Math.floor(per * 100) + '%';
  }
}

