import axios from "axios";
import qs from "qs";
import { ApiRoutes } from "src/api/routes";

export default class FeaturePage {
  fetchFeaturePage(params = {}) {
    return axios
      .get(ApiRoutes.featurePagesPath(), {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }

  show(id, params = {}) {
    return axios
      .get(ApiRoutes.featurePagePath(id), {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }
}
