import axios from "axios";
import { ApiRoutes } from "src/api/routes";

export default class RealStoreTag {
  fetchRealStoreTag() {
    return axios.get(ApiRoutes.realStoreTagsPath(), {}).then(response => {
      return response.data;
    });
  }
}
