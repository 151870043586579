import axios from "axios";
import { ApiRoutes } from "src/api/routes";

axios.defaults.headers["X-CSRF-TOKEN"] = document.querySelector("[name=csrf-token][content]").content;

export default class Token {
  setTokens(params) {
    return axios.post(ApiRoutes.setTokensPath(), params).then(response => {
      return response.data;
    });
  }

  setToken(params) {
    return axios.post(ApiRoutes.setTokenPath(), params).then(response => {
      return response.data;
    });
  }
}
