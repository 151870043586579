import { ApiRoutes } from "src/api/routes";
import axios from "axios";
import qs from "qs";

export default class Order {
  fetchAccountOrders(spreeOauthToken, params = {}) {
    return axios
      .get(`${ApiRoutes.accountOrdersPath()}`, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` },
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }

  show(spreeOauthToken, id, params = {}) {
    return axios
      .get(ApiRoutes.accountOrderPath(id), {
        headers: { Authorization: `Bearer ${spreeOauthToken}` },
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }
}
