import Swiper from 'swiper';
import { params } from '../_config';

const NewArrival = ()=>{

  /*
  box Swiper
  */

  let boxSwiper = new Swiper('#newArrivalBoxSwiper', {
    loop: true,
    speed: 300,
    navigation: {
      nextEl: '#newArrival .next',
      prevEl: '#newArrival .prev',
    }
  });
}

export default NewArrival;