<template>
  <!--お知らせ-->
  <section class="topics">
    <h2>
      <span class="en font-lato">TOPICS / お知らせ</span>
    </h2>
    <div class="boxes">
      <a v-for="n in notificationList" :key="n.id" class="box" :href="url(n)" :target="n.target_brank ? '_blank' : false" rel="noopener noreferrer">
        <p class="date">{{ n.start_at | dateFormat }}</p>
        <p class="title pre-wrap">{{ n.title }}</p>
      </a>
    </div>
    <a class="btn-more" href="/notifications/">お知らせ一覧へ<span class="icon-arrow-right"></span></a>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";
import dateFormat from "src/filters/dateFormat";

import { Routes } from "src/routes";

export default {
  filters: {
    dateFormat
  },
  data: function() {
    return {
      notificationList: []
    };
  },
  beforeMount() {
    this.fetchNotification();
  },
  methods: {
    async fetchNotification() {
      const res = await Client.notification.fetchNotification({ filter: { published: true, display_toppage: true }, sort: "default" });
      this.notificationList = await Deserializer(res);
    },
    url(notification) {
      return notification.link_url || Routes.notificationPath(notification.id);
    }
  }
};
</script>
