import qs from "qs";

export const Routes = {
  cartPath: () => "/cart",
  productPath: id => `/products/${id}`,
  productsPath: (params = {}) => `/products?${qs.stringify(params)}`,
  checkoutPath: state => `/checkout/${state}`,
  newsPath: id => `/news/${id}`,
  newsListPath: (params = {}) => `/news?${qs.stringify(params)}`,
  vendorsNewsListPath: (id, params = {}) => `/vendors/${id}/news?${qs.stringify(params)}`,
  completePath: number => `/orders/${number}`,
  photoCarouselPath: id => `/photo_carousels/${id}`,
  promoBannerPath: id => `/promo_banners/${id}`,
  featurePagePath: id => `/feature_pages/${id}`,
  featurePagesPath: (params = {}) => `/feature_pages?${qs.stringify(params)}`,
  vendorsFeaturePagesPath: (id, params = {}) => `/vendors/${id}/feature_pages?${qs.stringify(params)}`,
  realStoresPath: (params = {}) => `/corp/real_stores?${qs.stringify(params)}`,
  realStorePath: id => `/corp/real_stores/${id}`,
  topRealStoreNewsListPath: (params = {}) => `/corp/real_store_news?${qs.stringify(params)}`,
  realStoreNewsListPath: (realStoreId, params = {}) => `/corp/real_stores/${realStoreId}/real_store_news?${qs.stringify(params)}`,
  realStoreNewsPath: id => `/corp/real_store_news/${id}`,
  moleRealStoresPath: (params = {}) => `/real_stores?${qs.stringify(params)}`,
  moleRealStorePath: id => `/real_stores/${id}`,
  moleRealStoreNewsPath: id => `/real_store_news/${id}`,
  moleRealStoreNewsListPath: (realStoreId, params = {}) => `/real_stores/${realStoreId}/real_store_news?${qs.stringify(params)}`,
  moleTopRealStoreNewsListPath: (params = {}) => `/real_store_news?${qs.stringify(params)}`,
  taxonPath: slug => `/t/${slug}`,
  serviceListPath: (params = {}) => `/service_lists?${qs.stringify(params)}`,
  servicePath: id => `/service_lists/${id}`,
  notificationPath: id => `/notifications/${id}`,
  notificationListPath: () => "/notifications",
  vendorNotificationListPath: (id, params = {}) => `/vendors/${id}/notifications?${qs.stringify(params)}`,
  vendorPath: id => `/vendors/${id}`,
  vendorListPath: (params = {}) => `/vendors?${qs.stringify(params)}`,
  makerBrandsPath: () => "/maker_brands",
  makerBrandPath: (id, params = {}) => `/maker_brands/${id}?${qs.stringify(params)}`,
  favoriteListAllPath: (params = {}) => `/account/favorite_lists?${qs.stringify(params)}`,
  accountOrderPath: (params = {}) => `/account/orders?${qs.stringify(params)}`,
  accountOrderShowPath: (id, params = {}) => `/account/orders/${id}?${qs.stringify(params)}`,
  accountInfoPath: () => `/account/info`,
  editAccountPath: () => `/account/edit`,
  addressesPath: () => `/account/addresses`,
  addressNewPath: () => `/account/addresses/new`,
  addressEditPath: id => `/account/addresses/${id}/edit`,
  newReviewPath: productId => `/account/products/${productId}/reviews/new`,
  reviewListPath: () => `/account/reviews`,
  homePath: () => `/`,
  orderInquiryPath: orderNumber => `/account/orders/${orderNumber}/inquiry/new`,
  brandSiteNewsListPath: (params = {}) => `/corp/news?${qs.stringify(params)}`,
  brandSiteNewsPath: id => `/corp/news/${id}`,
  brandSiteContentListPath: (params = {}) => `/corp/recommend?${qs.stringify(params)}`
};
