export default function(price) {
  price = Number(price);

  // 価格表記(フォーマット)の条件分岐
  // 小数点以下がない時(.00のとき)は、整数表記かつ3桁ずつカンマ区切り 例) 1,188
  // 小数点以下があるときは小数点以下２桁まで0埋め 例) 1,058.40  1,319.76
  if (!price) {
    return "";
  } else if (!Number.isInteger(price)) {
    // 現状価格表記は小数点以下２桁までのため下記正規表現を使用
    // 小数点以下が4桁以上になると小数点以下もカンマ区切りとなるため、小数点以下が4桁以上になることがあるならば別方法を検討する
    // 参考： https://qiita.com/ariyo13/items/ec74de3cd721a68767fc
    return price.toFixed(2).replace(/(\d)(?=(\d{3})+($|\.\d+))/g, "$1,");
  } else {
    return price.toLocaleString();
  }
}
