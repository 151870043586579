import { ApiRoutes } from "src/api/routes";
import axios from "axios";
import qs from "qs";

export default class FavoriteList {
  createFavoriteList(spreeOauthToken, params) {
    return axios
      .post(ApiRoutes.favoriteListPath(), params, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  fetchFavoriteListAll(spreeOauthToken, params = {}) {
    return axios
      .get(ApiRoutes.favoriteListAllPath(), {
        headers: { Authorization: `Bearer ${spreeOauthToken}` },
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }

  removeFavorite(spreeOauthToken, variantId) {
    return axios
      .post(
        ApiRoutes.removeFavoriteListPath(variantId),
        {},
        {
          headers: { Authorization: `Bearer ${spreeOauthToken}` }
        }
      )
      .then(response => {
        return response.data;
      });
  }
}
