import Swiper from 'swiper';

const Kv = ()=>{

  /*
  Swiper
  */
  let kvSwiper = new Swiper('#kvSwiper', {
    loop: true,
    loopAdditionalSlides: 1,
    speed: 400,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: { 
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      prevEl: '#kvSwiper .swiper-button-prev',
      nextEl: '#kvSwiper .swiper-button-next',
    }
  });
  
  let kvMiniSwiper = new Swiper('#kvMiniSwiper',{
    loop: true,
    speed: 400,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: { 
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      prevEl: '#kvSwiper .swiper-button-prev',
      nextEl: '#kvSwiper .swiper-button-next',
    }
  });
}

export default Kv;