<template>
  <!--新着商品-->
  <section id="newArrival" class="new-arrivals">
    <h2>
      <span class="en font-lato">NEW ARRIVALS / 新着商品</span>
    </h2>
    <!--PCレイアウト用-->
    <div class="boxes-container-pc">
      <product-card-top-page-new-arrival v-for="product in newArrivalList" :key="`newArrival${product.id}`" :product="product" />
    </div>
    <!--SPレイアウト用-->
    <div id="newArrivalBoxSwiper" class="boxes-container-sp swiper-container">
      <div class="boxes swiper-wrapper">
        <div v-for="(products, index) in eachNewArrivalList" :key="`swiper${index}`" class="swiper-slide">
          <div class="swiper-slide">
            <product-card-top-page-new-arrival v-for="product in products" :key="`newArrivalSp${product.id}`" :product="product" />
          </div>
        </div>
      </div>
      <div class="prev"><span class="icon-arrow-right"></span></div>
      <div class="next"><span class="icon-arrow-right"></span></div>
    </div>
    <a class="btn-more" :href="newArrivalProductUrl()">もっと見る<span class="icon-arrow-right"></span></a>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";
import NewArrival from "stylesheets/js/top/_new_arrival.js";
import { chunk } from "lodash";
import ProductCardTopPageNewArrival from "src/components/ProductCardTopPageNewArrival";
import { Routes } from "src/routes";

export default {
  components: {
    ProductCardTopPageNewArrival
  },
  data: function() {
    return {
      newArrivalList: []
    };
  },
  computed: {
    eachNewArrivalList() {
      return chunk(this.newArrivalList, 4);
    }
  },
  beforeMount() {
    this.fenchNewArrivalList().then(() => {
      NewArrival();
    });
  },
  methods: {
    async fenchNewArrivalList() {
      const res = await Client.product.fetchProduct({ sort_by: "newest-first", include: "maker_brand,images", page: 1, per_page: 6 });
      this.newArrivalList = await Deserializer(res);
    },
    newArrivalProductUrl() {
      return Routes.productsPath({ sort_by: "newest-first" });
    }
  }
};
</script>
