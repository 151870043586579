<template>
  <!--PICK UP-->
  <section id="pickup" class="pickup">
    <h2>
      <span class="en font-lato">PICK UP / 特集</span>
    </h2>
    <div class="gray-area">
      <div v-if="featurePageList.length > 0" class="boxes-container">
        <div class="boxes boxes-1">
          <div class="box">
            <a :href="url(primaryFeaturePage)" :target="primaryFeaturePage.target_brank ? '_blank' : false" rel="noopener noreferrer">
              <div v-if="primaryFeaturePage.image" class="pic">
                <img :src="primaryFeaturePage.image.style_large.url" />
              </div>
              <div class="txt pre-wrap">
                <p>{{ primaryFeaturePage.title }}</p>
              </div>
            </a>
          </div>
        </div>
        <div class="boxes boxes-4">
          <div v-for="fp in otherFeaturePage" :key="`fp${fp.id}`" class="box">
            <a :href="url(fp)" :target="fp.target_brank ? '_blank' : false" rel="noopener noreferrer">
              <div class="pic">
                <img :src="fp.image.style_large.url" />
              </div>
              <div class="txt pre-wrap">
                <p>{{ fp.title }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <a class="btn-more" href="/feature_pages">もっと見る<span class="icon-arrow-right"></span></a>
    </div>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";
import { Routes } from "src/routes";

export default {
  data: function() {
    return {
      featurePageList: []
    };
  },
  computed: {
    primaryFeaturePage() {
      return this.featurePageList[0];
    },
    otherFeaturePage() {
      const list = this.featurePageList.filter(fp => fp.id != this.primaryFeaturePage.id);
      return list.slice(0, 4);
    }
  },
  beforeMount() {
    this.fetchFeaturePage();
  },
  methods: {
    async fetchFeaturePage() {
      const res = await Client.featurePage.fetchFeaturePage({
        include: "image",
        filter: { published_display_in_index: true, display_toppage: true },
        sort: "top_page"
      });
      this.featurePageList = await new Deserializer(res);
    },
    url(featurePage) {
      return featurePage.link_url || Routes.featurePagePath(featurePage.permalink);
    }
  }
};
</script>
