<template>
  <div v-if="aveStar || star" class="stars">
    <template v-if="aveStar === '0.0'">
      <span class="icon-star-b" style="color: #ffffff;"></span>
    </template>
    <template v-else-if="aveStar === '0.5'">
      <span class="icon-star-c"></span><span class="icon-star-b"></span><span class="icon-star-b"></span><span class="icon-star-b"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '1.0' || star === 1">
      <span class="icon-star-a"></span><span class="icon-star-b"></span><span class="icon-star-b"></span><span class="icon-star-b"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '1.5'">
      <span class="icon-star-a"></span><span class="icon-star-c"></span><span class="icon-star-b"></span><span class="icon-star-b"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '2.0' || star === 2">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-b"></span><span class="icon-star-b"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '2.5'">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-c"></span><span class="icon-star-b"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '3.0' || star === 3">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-b"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '3.5'">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-c"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '4.0' || star === 4">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-b"></span>
    </template>
    <template v-else-if="aveStar === '4.5'">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-c"></span>
    </template>
    <template v-else-if="aveStar === '5.0' || star === 5">
      <span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span><span class="icon-star-a"></span>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProductReviewStar",
  props: {
    aveStar: { type: String, require: true },
    star: { type: Number, require: true }
  }
};
</script>
