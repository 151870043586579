<template>
  <div class="pic">
    <div :style="isThreeProducts && isMobile ? threeProductBannerSize : notThreeProductBannerSize" class="cart-banner-size">
      <img v-if="bannerImageName" :src="bannerImageUrl" :alt="bannerImageName" />
    </div>
    <img v-if="displayImage" :src="displayImage.style_large.url" :alt="productName" />
    <img v-else src="/images/common/large.png" :alt="productName" />
  </div>
</template>

<script>
export default {
  name: "ProductCardDetailImage",
  props: {
    images: { type: Array, required: true },
    productName: { type: String, required: true },
    isIndexImage: { type: Boolean, default: false },
    product: { type: Object, required: false },
    isThreeProducts: { type: Boolean, default: false }
  },
  data: function() {
    return {
      bannerImageUrl: "",
      bannerImageName: null,
      isMobile: false,
      threeProductBannerSize: {
        width: "60px",
        height: "25px"
      },
      notThreeProductBannerSize: {
        width: "75px",
        height: "25px"
      }
    };
  },
  computed: {
    displayImage() {
      const indexImages = this.images.filter(x => x.is_display_in_index === true);
      const images = this.images.filter(x => x.is_display_in_index === false);
      if (this.isIndexImage) {
        return indexImages[0] || images[0];
      } else {
        return images[0] || indexImages[0];
      }
    }
  },
  created() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", this.checkIsMobile);
  },
  mounted() {
    const dualPricingHeader = this.product.dual_pricing_header;
    const onSaleTiming = this.product.dual_pricing_display_timing === "on_sale";

    switch (dualPricingHeader) {
      case "bargain":
        if (onSaleTiming) {
          this.bannerImageUrl = `/images/product_banners/bargain.jpg`;
          this.bannerImageName = "お買い得商品";
        }
        break;
      case "sale_1":
      case "sale_2":
        if (onSaleTiming && this.product.on_sale_flag) {
          this.bannerImageUrl = `/images/product_banners/sale.jpg`;
          this.bannerImageName = "セール";
        }
        break;
      case "outlet":
        this.bannerImageUrl = `/images/product_banners/outlet.jpg`;
        this.bannerImageName = "アウトレット";
        break;
      case "discount_display": {
        const discountRate = Math.floor(((1 * 10 - (this.product.price / this.product.brand_suggested_price) * 10) / 10) * 100);
        this.bannerImageUrl = `/images/product_banners/discount_display_${discountRate}.jpg`;
        this.bannerImageName = `${discountRate}%OFF`;
        break;
      }
    }
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>
