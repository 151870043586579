import axios from "axios";
import qs from "qs";
import { ApiRoutes } from "src/api/routes";

export default class ProductCarousel {
  fetchProductCarousel(spreeOauthToken, params = {}) {
    return axios
      .get(ApiRoutes.productCarouselsPath(), {
        headers: { Authorization: `Bearer ${spreeOauthToken}` },
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }
}
