<template>
  <a :href="`/products/${product.slug}`">
    <div class="inner">
      <product-card-detail-image :product="product" :images="product.images" :product-name="product.name" :isThreeProducts="isThreeProducts" />
      <div class="txt">
        <p v-if="product.maker_brand" class="brand">{{ product.maker_brand.name }}（{{ product.maker_brand.sub_name }}）</p>
        <p class="name">{{ product.name }}</p>
        <product-card-detail-price :product="product" />
        <product-review-star :ave-star="product.accept_review_star_ave" />
      </div>
    </div>
  </a>
</template>

<script>
import { range } from "lodash";
import ProductCardDetailPrice from "src/components/ProductCardDetailPrice";
import ProductCardDetailImage from "src/components/ProductCardDetailImage";
import productReviewStar from "src/components/ProductReviewStar";

export default {
  name: "ProductCardTopPage",
  components: {
    ProductCardDetailPrice,
    ProductCardDetailImage,
    productReviewStar
  },

  props: {
    product: { type: Object, required: true },
    enableStar: { type: Boolean, default: false },
    isThreeProducts: { type: Boolean, default: false }
  },
  methods: {
    myrange(x) {
      return range(x);
    }
  }
};
</script>
