import axios from "axios";
import { ApiRoutes } from "src/api/routes";
import qs from "qs";

export default class RealStoreInfo {
  fetchRealStoreInfo(params = {}) {
    return axios
      .get(ApiRoutes.realStoreInfosPath(), {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }
}
