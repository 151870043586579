const params = {
    breakpoint:768,
    getPcSp:()=>{
        if (window.innerWidth <= params.breakpoint) {
            return 'sp';
        } else {
            return 'pc';
        }
    },
    categoryOptions: {
        'すべて': {},
        'シティサイクル': {
            'すべて': {},
            'シティサイクル': {},
            '子乗せサイクル': {}
        },
        '電動アシストサイクル': {
            'すべて': {},
            'シティタイプ': {},
            'スポーツタイプ': {},
            'その他': {}
        },
        '電動アシスト子乗せサイクル': {
            'すべて': {},
            '前子乗せ': {},
            '後子乗せ': {},
            'チャイルドシートなし': {}
        },
        '折りたたみ・小径自転車': {
            'すべて': {},
            '折りたたみ自転車': {},
            '小径自転車': {}
        },
        'キッズサイクル・乗り物': {
            'すべて': {},
            '幼児用自転車': {},
            'ジュニアスポーツサイクル': {},
            'ジュニアシティサイクル': {},
            '一輪車': {},
            '三輪車': {},
            '乗用玩具': {},
            'キックバイク': {}
        },
        'スポーツサイクル': {
            'すべて': {},
            'ロードバイク': {},
            'クロスバイク': {},
            'マウンテンバイク': {},
            'その他': {},
        },
        'アクセサリー': {
            'すべて': {},
            'カギ': {},
            'ライト': {
                'すべて': {},
                '前用': {},
                '後用': {},
                '反射グッズ': {}
            },
            '自転車カバー': {
                'すべて': {},
                '自転車カバー': {},
                'チャイルドシートカバー': {},
                'ハンドルカバー': {},
                'サドルカバー': {}
            },
            '空気入れ': {
                'すべて': {},
                '空気入れ': {},
                '携帯空気入れ': {}
            },
            'カゴ': {
                'すべて': {},
                '前カゴ': {},
                '後カゴ': {}
            },
            'チャイルドシート': {
                'すべて': {},
                '前用': {},
                '後用': {}
            },
            '工具・ケミカル類': {
                'すべて': {},
                '工具': {},
                'ケミカル': {}
            },
            'サイクルコンピュータ': {},
            '電動アシスト車用バッテリー': {
                'すべて': {},
                'パナソニック': {},
                'ブリヂストン': {},
                'ヤマハ': {},
                'その他': {}
            },
            'キャリア': {},
            'スタンド': {},
            'ディスプレイスタンド・ワークスタンド': {},
            'ボトルケージ・ボトル': {},
            'ドロヨケ（フェンダー）': {},
            'ベル': {},
            'バックミラー': {},
            'ローラー台・トレーナー用品': {},
            'その他アクセサリー': {}
        },
        'ウェア・グッズ・バッグ': {
            'すべて': {},
            'サングラス・ゴーグル': {},
            'ヘルメット': {},
            'キャップ・バンダナ': {},
            'フェイスマスク・ネックウェア': {},
            'グローブ': {},
            'アームカバー': {},
            'ジャージ': {},
            'ジャケット': {},
            'ウィンドブレーカー': {},
            'インナーウェア': {},
            'サイクルパンツ': {},
            'サイクルスカート': {},
            'レーサーパンツ・タイツ': {},
            'レッグカバー': {},
            'シューズ': {},
            'シューズカバー': {},
            'ソックス': {},
            'プロテクター': {},
            'レインウェア': {},
            'その他ウェア': {},
            'バッグ類': {
                'すべて': {},
                'サドルバッグ': {},
                'スマホケース': {},
                'トップチューブバッグ・フレームバッグ': {},
                'バックパック': {},
                'フロントバッグ': {},
                'ホイールバッグ・ホイールカバー': {},
                'メッセンジャーバッグ・ショルダーバッグ': {},
                'リアキャリアバッグ': {},
                '輪行袋・キャリーケース': {},
                '輪行小物類': {}
            }
        },
        '自転車部品': {
            'すべて': {},
            'サドル・シートポスト': {},
            'スプロケット・ギア': {},
            'タイヤ・チューブ・小物': {},
            'チェーン': {},
            'チェーンリング・クランク': {},
            'ディレイラー': {},
            'ハンドル・ステム': {},
            'バーテープ・グリップ': {},
            'ブレーキ': {},
            'ブレーキレバー・シフトレバー': {},
            'ブレーキケーブル・シフトケーブル': {},
            'フレーム・フォーク': {},
            'ペダル': {},
            'ヘッドパーツ・コラムパーツ': {},
            'ホイール・ハブ・リム': {},
            'ボトムブラケット': {},
            'その他': {}
        }
    }
}



export {params};

