import axios from "axios";
import { ApiRoutes } from "src/api/routes";

axios.defaults.headers["X-CSRF-TOKEN"] = document.querySelector("[name=csrf-token][content]").content;

export default class AuthAccessToken {
  createToken(params) {
    return axios
      .post(ApiRoutes.authAccessTokenPath(), params)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
  setToken(params) {
    return axios.post(ApiRoutes.setAuthAccessTokenPath(), params).then(response => {
      return response.data;
    });
  }
}
