<template>
  <div>
    <!--カテゴリー検索-->
    <section id="category" class="category">
      <h2>
        <span class="en font-lato">CATEGORY / カテゴリー検索</span>
      </h2>
      <div class="boxes">
        <a v-for="taxon in taxonList" :key="`category${taxon.id}`" class="box" @click="onCategoryClick(taxon)">
          <div class="inner">
            <div class="pic">
              <img v-if="taxon.image" :src="taxon.image.style_large.url" />
            </div>
            <p class="name pc">{{ taxon.name }}</p>
            <p class="name sp">{{ taxon.short_name || taxon.name }}</p>
          </div>
        </a>
      </div>
    </section>

    <!--category-modal-->
    <div v-if="categoryClickedTaxon" id="categoryModal" class="category-modal">
      <div class="category-modal-inner">
        <div class="close"><span class="icon-close"></span></div>
        <div v-if="categoryClickedTaxon" class="category-modal-box">
          <div class="prod-txt">
            <div class="prod">
              <img v-if="categoryClickedTaxon.image" :src="categoryClickedTaxon.image.style_large.url" />
            </div>
            <div class="txt" v-html="`${categoryClickedTaxon.description}`"></div>
          </div>
          <div class="links">
            <div class="bar">
              <a :href="productPath(categoryClickedTaxon.id)">すべての{{ categoryClickedTaxon.name }}<span class="icon-arrow-right"></span></a>
            </div>

            <ul>
              <li v-for="child in childsTaxonList" :key="`modalTaxonName${child.id}`">
                <a :href="productPath(categoryClickedTaxon.id, child.id)">{{ child.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";
import Category from "stylesheets/js/top/_category.js";
import { Routes } from "src/routes";

export default {
  data: function() {
    return {
      taxonList: [],
      childsTaxonList: [],
      categoryClickedTaxon: null
    };
  },
  beforeMount() {
    this.fetchTaxon().then(() => {
      Category();
    });
  },
  methods: {
    productPath(rootId, childId) {
      const params = { filter: { first_taxon: rootId, second_taxon: childId } };
      return Routes.productsPath(params);
    },
    async fetchTaxon() {
      const res = await Client.taxon.fetchTaxon({ filter: { roots: true }, include: "image" });
      this.taxonList = await new Deserializer(res);
    },
    async onCategoryClick(taxon) {
      this.categoryClickedTaxon = taxon;
      const res = await Client.taxon.fetchTaxon({ filter: { parent_id: this.categoryClickedTaxon.id } });
      this.childsTaxonList = await new Deserializer(res);
    }
  }
};
</script>
