import { ApiRoutes } from "src/api/routes";
import axios from "axios";

export default class Tel {
  updateTel(spreeOauthToken, params) {
    return axios
      .patch(`${ApiRoutes.telPath()}`, params, {
        headers: { Authorization: `Bearer ${spreeOauthToken}` }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}
