import axios from "axios";
import { ApiRoutes } from "src/api/routes";
import qs from "qs";

export default class RealStoreNews {
  fetchRealStoreNews(params = {}) {
    return axios
      .get(ApiRoutes.realStoreNewsListPath(), {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }

  show(id, params = {}) {
    return axios
      .get(ApiRoutes.realStoreNewsPath(id), {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      })
      .then(response => {
        return response.data;
      });
  }
}
