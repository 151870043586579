<template>
  <!--お気に入り 閲覧履歴-->
  <section class="my-list">
    <div class="my-list-inner">
      <div class="list fav">
        <div class="list-header">
          <h2><span class="icon-heart-border"></span>お気に入りリスト</h2>
          <a class="more" href="/account/favorite_lists">
            もっと見る
            <div class="arrow"><span class="icon-arrow-right"></span></div>
          </a>
        </div>
        <div class="list-contents">
          <template v-if="logined">
            <template v-if="account.favorite_products.length > 0">
              <div id="favSwiper" class="swiper-container">
                <div class="swiper-wrapper">
                  <div v-for="p in account.favorite_products" :key="`favorite${p.id}`" class="swiper-slide">
                    <product-card-top-page :product="p" :enable-star="true" :isThreeProducts="true" />
                  </div>
                </div>
              </div>
              <div id="favSeekbar" class="seekbar">
                <div class="head"></div>
                <div class="bar"></div>
              </div>
            </template>
            <template v-else>
              <div class="mes-frame green">
                <p>お気に入り登録はありません。</p>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="mes-frame green">
              <p>
                お気に入りの商品を登録しておくことで、<br />イオンバイクモールを便利に<br class="sp" />ご利用いただけます。<br />
                <a class="reg" href="/signup/">会員登録</a>をしていただくことで、<br />この機能がご利用いただけます。
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="list history">
        <div class="list-header">
          <h2><span class="icon-clock"></span>閲覧履歴</h2>
          <a class="more" href="/history">
            もっと見る
            <div class="arrow"><span class="icon-arrow-right"></span></div>
          </a>
        </div>
        <div class="list-contents">
          <template v-if="sortedProductHistory.length > 0">
            <div id="historySwiper" class="swiper-container">
              <div class="swiper-wrapper">
                <div v-for="p in sortedProductHistory" :key="`history${p.id}`" class="swiper-slide">
                  <product-card-top-page :product="p" :enable-star="true" :isThreeProducts="true" />
                </div>
              </div>
            </div>
            <div id="historySeekbar" class="seekbar">
              <div class="head"></div>
              <div class="bar"></div>
            </div>
          </template>
          <template v-else>
            <div class="mes-frame green">
              <p>閲覧履歴はありません。</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";
import Mylist from "stylesheets/js/top/_my_list.js";
import ProductCardTopPage from "src/components/ProductCardTopPage";

export default {
  components: {
    ProductCardTopPage
  },
  data: function() {
    return {
      logined: false,
      account: {},
      productHistoryIds: [],
      productHistory: []
    };
  },
  computed: {
    sortedProductHistory() {
      const findIdxId = elem => this.productHistoryIds.indexOf(elem.id);
      return this.productHistory.slice().sort((a, b) => findIdxId(b) - findIdxId(a));
    }
  },
  beforeMount() {
    Promise.all([this.fetchFavoriteList(), this.fetchProductHistory()]).then(() => {
      // TODO: デザインがそもそも動いてない気がする。
      Mylist();
    });
  },
  methods: {
    async fetchFavoriteList() {
      const apiTokens = await Client.apiToken.fetch();
      const oauthToken = apiTokens.oauth_token;
      if (oauthToken === null) {
        this.logined = false;
        return;
      } else {
        this.logined = true;
        const res = await Client.account.fetchAccount(oauthToken, { include: "favorite_products.maker_brand,favorite_products.images" });
        this.account = await Deserializer(res);
      }
    },
    async fetchProductHistory() {
      this.productHistoryIds = await Client.productHistory.fetchProductHistory();
      if (this.productHistoryIds.length > 0) {
        const res = await Client.product.fetchProduct({ filter: { ids: this.productHistoryIds.join(",") }, include: "maker_brand,images" });
        this.productHistory = await Deserializer(res);
      }
    }
  }
};
</script>
